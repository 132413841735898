import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import money from 'v-money';

import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/sweet-alerts';

// Format Date
import '@/@core/utils/format-date';
// Vue Filter
import '@/@core/utils/vue-filters';

import titleMixin from '@core/mixins/titleMixin';
Vue.mixin(titleMixin);

// Maps
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAi5wVEyfmmWt1ZlULM09KOnVsZRtg2grs',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
});

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// register directive v-money and component <money>
Vue.use(money, { precision: 4 })

// Websocket
// import VueEcho from 'vue-echo-laravel';
// window.io = require('socket.io-client');
// Vue.use(VueEcho, {
//   broadcaster: 'socket.io',
//   host: process.env.WEBSOCKET_DOMAIN,
// });

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
